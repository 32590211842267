<template>
  <div class="modal-overlay" v-if="isOpen" @click="closePrivacidadeModal">
    <div class="modal-content" @click.stop>
      <h2>POLITICAS DE PRIVACIDADE</h2>
      <div class="modal-body" v-html="renderedMarkdown"></div>
      <button @click="closePrivacidadeModal">Fechar</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, computed } from "vue";
import { marked } from "marked";

// Define as props e emite eventos
defineProps<{
  isOpen: boolean;
}>();

const emit = defineEmits<{
  (event: "close"): void;
}>();

// Função para fechar o modal
const closePrivacidadeModal = () => {
  emit("close");
};

// Conteúdo Markdown dos Termos de Uso
const markdownContent = `



Esta Política de Privacidade explica como protegemos suas informações pessoais ao utilizar nossos serviços, assegurando uma experiência de compra segura e transparente. Note que o acesso e a coleta de dados para o checkout e acesso ao infoproduto são de responsabilidade da plataforma de vendas utilizada.

<br>

**Coleta e Uso de Informações**

A plataforma de vendas de infoprodutos é responsável por coletar informações como nome, e-mail e detalhes do pagamento necessários para processar seu checkout e garantir o acesso ao infoproduto. Esses dados são utilizados exclusivamente para transações e melhorias de serviço.

<br>

**Finalidade do Tratamento de Dados**

Os dados coletados são utilizados pela plataforma para garantir a entrega do infoproduto, melhorar os serviços oferecidos e, quando autorizado, enviar ofertas promocionais que possam ser de seu interesse.

<br>

**Consentimento**

Ao fornecer seus dados à plataforma, você consente com a coleta e uso conforme descrito. Você pode revogar esse consentimento a qualquer momento, seguindo as diretrizes de contato da plataforma.

<br> 

**Medidas de Segurança**

Empregamos técnicas avançadas de segurança, incluindo criptografia SSL, para proteger suas informações contra acessos não autorizados, garantindo que seus dados permaneçam seguros.

<br>

**Acesso e Controle de Dados**

Você tem o direito de acessar, corrigir ou excluir suas informações pessoais a qualquer momento. Para exercer esses direitos, entre em contato diretamente com a plataforma de vendas utilizada.

<br>

**Retenção de Dados**

A plataforma retém suas informações apenas enquanto necessário para cumprir as finalidades descritas nesta política, ou conforme exigido por lei.

<br>

**Compartilhamento de Informações**

Os dados são compartilhados apenas com parceiros de confiança para processar transações e entregar produtos, sempre respeitando a segurança e confidencialidade das informações.

<br>

**Cookies e Tecnologias Semelhantes**

Utilizamos cookies para melhorar a funcionalidade do site e personalizar sua experiência de compra. Você pode ajustar as configurações de cookies no seu navegador.

<br>

**Proteção de Informações de Menores**

Não coletamos intencionalmente informações de menores de 13 anos sem consentimento parental. Se descobrirmos que coletamos informações de um menor, tomaremos medidas para remover esses dados.

<br>

**Alterações na Política de Privacidade**

Podemos atualizar esta política periodicamente. Notificaremos sobre alterações significativas e encorajamos você a revisar esta política regularmente.
`;

// Renderiza o Markdown como HTML
const renderedMarkdown = computed(() => marked(markdownContent));
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: rgb(0, 0, 0);
  padding: 30px;
  border-radius: 12px;
  max-width: 800px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.modal-content h2 {
  font-size: 24px;
  font-weight: bold;
  color: #ff0000;
  margin-bottom: 20px;
  text-align: center;
}

.modal-body {
  margin: 20px 0;
  color: #d1d1d1;
}

button {
  padding: 12px 24px;
  background-color: #ff0000;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  display: block;
  margin: 20px auto 0;
}

button:hover {
  background-color: #740000;
}
</style>
