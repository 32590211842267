import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

import { computed } from "vue";
import { marked } from "marked";

// Define as props e emite eventos
const markdownContent = `



Esta Política de Privacidade explica como protegemos suas informações pessoais ao utilizar nossos serviços, assegurando uma experiência de compra segura e transparente. Note que o acesso e a coleta de dados para o checkout e acesso ao infoproduto são de responsabilidade da plataforma de vendas utilizada.

<br>

**Coleta e Uso de Informações**

A plataforma de vendas de infoprodutos é responsável por coletar informações como nome, e-mail e detalhes do pagamento necessários para processar seu checkout e garantir o acesso ao infoproduto. Esses dados são utilizados exclusivamente para transações e melhorias de serviço.

<br>

**Finalidade do Tratamento de Dados**

Os dados coletados são utilizados pela plataforma para garantir a entrega do infoproduto, melhorar os serviços oferecidos e, quando autorizado, enviar ofertas promocionais que possam ser de seu interesse.

<br>

**Consentimento**

Ao fornecer seus dados à plataforma, você consente com a coleta e uso conforme descrito. Você pode revogar esse consentimento a qualquer momento, seguindo as diretrizes de contato da plataforma.

<br> 

**Medidas de Segurança**

Empregamos técnicas avançadas de segurança, incluindo criptografia SSL, para proteger suas informações contra acessos não autorizados, garantindo que seus dados permaneçam seguros.

<br>

**Acesso e Controle de Dados**

Você tem o direito de acessar, corrigir ou excluir suas informações pessoais a qualquer momento. Para exercer esses direitos, entre em contato diretamente com a plataforma de vendas utilizada.

<br>

**Retenção de Dados**

A plataforma retém suas informações apenas enquanto necessário para cumprir as finalidades descritas nesta política, ou conforme exigido por lei.

<br>

**Compartilhamento de Informações**

Os dados são compartilhados apenas com parceiros de confiança para processar transações e entregar produtos, sempre respeitando a segurança e confidencialidade das informações.

<br>

**Cookies e Tecnologias Semelhantes**

Utilizamos cookies para melhorar a funcionalidade do site e personalizar sua experiência de compra. Você pode ajustar as configurações de cookies no seu navegador.

<br>

**Proteção de Informações de Menores**

Não coletamos intencionalmente informações de menores de 13 anos sem consentimento parental. Se descobrirmos que coletamos informações de um menor, tomaremos medidas para remover esses dados.

<br>

**Alterações na Política de Privacidade**

Podemos atualizar esta política periodicamente. Notificaremos sobre alterações significativas e encorajamos você a revisar esta política regularmente.
`;

// Renderiza o Markdown como HTML

export default /*@__PURE__*/_defineComponent({
  __name: 'PoliticasDePrivacidade',
  props: {
    isOpen: { type: Boolean }
  },
  emits: ["close"],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

// Função para fechar o modal
const closePrivacidadeModal = () => {
  emit("close");
};

// Conteúdo Markdown dos Termos de Uso
const renderedMarkdown = computed(() => marked(markdownContent));

return (_ctx: any,_cache: any) => {
  return (_ctx.isOpen)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "modal-overlay",
        onClick: closePrivacidadeModal
      }, [
        _createElementVNode("div", {
          class: "modal-content",
          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
        }, [
          _cache[1] || (_cache[1] = _createElementVNode("h2", null, "POLITICAS DE PRIVACIDADE", -1)),
          _createElementVNode("div", {
            class: "modal-body",
            innerHTML: renderedMarkdown.value
          }, null, 8, _hoisted_1),
          _createElementVNode("button", { onClick: closePrivacidadeModal }, "Fechar")
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})