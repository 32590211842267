import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, vShow as _vShow, withDirectives as _withDirectives, withModifiers as _withModifiers, createVNode as _createVNode, createStaticVNode as _createStaticVNode } from "vue"
import _imports_0 from '../../public/Images/secret_logo.png'
import _imports_1 from '../../public/Images/header.png'
import _imports_2 from '../../public/Images/Vector.png'
import _imports_3 from '../../public/Images/section1.jpg'
import _imports_4 from '../../public/Images/cel1.png'
import _imports_5 from '../../public/Images/cel2.png'
import _imports_6 from '../../public/Images/gota.png'
import _imports_7 from '../../public/Images/selo.png'
import _imports_8 from '../../public/Images/cartoes.png'
import _imports_9 from '../../public/Images/selos2.png'


const _hoisted_1 = { class: "section-container" }
const _hoisted_2 = { class: "section-wrapper" }
const _hoisted_3 = { class: "text-section" }
const _hoisted_4 = { class: "steps-list" }
const _hoisted_5 = { class: "step-icon" }
const _hoisted_6 = { class: "step-number" }
const _hoisted_7 = { class: "step-text" }
const _hoisted_8 = { class: "seduction-section" }
const _hoisted_9 = { class: "container-seduction" }
const _hoisted_10 = { class: "seduction" }
const _hoisted_11 = { class: "features" }
const _hoisted_12 = { class: "promo-section" }
const _hoisted_13 = { class: "container-promo" }
const _hoisted_14 = { class: "timer" }
const _hoisted_15 = { class: "footer-banner" }
const _hoisted_16 = { class: "positions-section" }
const _hoisted_17 = { class: "content-container" }
const _hoisted_18 = { class: "table-container" }
const _hoisted_19 = { class: "container-learning" }
const _hoisted_20 = { class: "learning-section" }
const _hoisted_21 = { class: "learning-list" }
const _hoisted_22 = ["onClick"]
const _hoisted_23 = { class: "panel" }
const _hoisted_24 = { class: "footer" }
const _hoisted_25 = { class: "footer-container" }
const _hoisted_26 = { class: "footer-left" }
const _hoisted_27 = { class: "footer-links" }

import { ref, onMounted, onUnmounted } from "vue";
import TermosDeUsoModal from "@/components/TermosDeUsoModal.vue";
import PoliticasDePrivacidade from "@/components/PoliticasDePrivacidade.vue";

type LearningItem = {
  title: string;
  content: string;
};

interface ButtonConfig {
  largeScreen: string;
  smallScreen: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'HomeView',
  setup(__props) {

const isModalOpen = ref(false);
const isModalPrivOpen = ref(false);

const openModal = () => {
  isModalOpen.value = true;
};

const openModalPrivacidade = () => {
  isModalPrivOpen.value = true;
};

const closePrivModal = () => {
  isModalPrivOpen.value = false;
};

const closeModal = () => {
  isModalOpen.value = false;
};

const scrollingText = ref<HTMLDivElement | null>(null);

onMounted(() => {
  const textContainer = scrollingText.value;

  if (textContainer) {
    const containerWidth = textContainer.offsetWidth;
    const contentWidth = textContainer.scrollWidth;

    if (contentWidth < containerWidth * 2) {
      const originalContent = textContainer.innerHTML;
      textContainer.innerHTML += originalContent;
    }

    const totalWidth = textContainer.scrollWidth;
    textContainer.style.animationDuration = `${totalWidth / 100}s`;
  }
});

const steps = [
  {
    title: "Entenda a Anatomia Feminina",
    description:
      "Comece por compreender as zonas erógenas femininas e como a estimulação de cada área contribui para o prazer.",
  },
  {
    title: "Comunicação Aberta e Sincera",
    description:
      "Construa uma atmosfera de confiança e comunicação aberta com sua parceira. Pergunte o que ela gosta e quais são seus desejos, para que possa oferecer o prazer que ela realmente busca.",
  },
  {
    title: "Domine a Arte das Preliminares",
    description:
      "Dedique tempo às preliminares e explore diferentes toques, beijos e carícias que despertem a sensualidade da sua parceira.",
  },
  {
    title: "Explore Posições Inovadoras",
    description:
      "Varie as posições sexuais e descubra quais delas despertam o maior prazer em sua parceira.",
  },
  {
    title: "Aprenda Técnicas de Estimulação",
    description:
      "Desvende as melhores técnicas para estimular o clitóris, o Ponto G e outras áreas sensíveis proporcionando orgasmos intensos e satisfatórios para sua parceira.",
  },
  {
    title: "Conecte-se Emocionalmente",
    description:
      "O prazer sexual vai além do físico. Crie uma conexão emocional forte com sua parceira demonstre afeto, carinho e atenção, intensificando a experiência íntima.",
  },
];

const features = [
  {
    title: "Linguagem Corporal e Sedução",
    description:
      "O olhar, o toque, a postura e a maneira como você se move podem comunicar muito sobre seus desejos e intensificar o clima de sedução. Aprenda a usar a linguagem corporal a seu favor.",
  },
  {
    title: "Crie um Ambiente Romântico",
    description:
      "Um ambiente relaxante, com música suave, iluminação suave, aromas agradáveis e um toque de sensualidade contribui para a atmosfera ideal para a sedução e o prazer.",
  },
  {
    title: "Desperte os Sentidos",
    description:
      "Explore diferentes texturas, aromas, sabores e sons para estimular os sentidos da sua parceira. Use a criatividade para criar uma experiência multissensorial que intensifique o prazer.",
  },
];

const positions = [
  {
    name: "Clássica e versátil",
    benefits: "Permite fácil acesso ao clitóris e ao ponto G.",
  },
  {
    name: "O Controle nas Mãos",
    benefits:
      "Quando assumir o comando transforma a intensidade em algo memorável.",
  },
  {
    name: "Movimentos Livres e Ousados",
    benefits:
      "Oferece maior liberdade de movimento para a mulher, intensificando o prazer e o controle.",
  },
  {
    name: "Intimidade Redefinida",
    benefits:
      "Uma posição que aproxima, intensifica os toques e cria momentos inesquecíveis.",
  },
  {
    name: "Acesso às Sensações Mais Intensas",
    benefits:
      "Proporciona experiências profundas que deixam marcas na memória.",
  },
];

const countdown = ref({
  dias: "00",
  horas: "00",
  minutos: "00",
  segundos: "00",
});
const countdownTarget = new Date().getTime() + 2 * 60 * 60 * 1000;

const startCountdown = () => {
  setInterval(() => {
    const now = new Date().getTime();
    const distance = countdownTarget - now;

    if (distance <= 0) return;
    countdown.value = {
      dias: String(Math.floor(distance / (1000 * 60 * 60 * 24))).padStart(
        2,
        "0"
      ),
      horas: String(
        Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      ).padStart(2, "0"),
      minutos: String(
        Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      ).padStart(2, "0"),
      segundos: String(Math.floor((distance % (1000 * 60)) / 1000)).padStart(
        2,
        "0"
      ),
    };
  }, 1000);
};

onMounted(() => {
  startCountdown();
});

const activeIndex = ref<number | null>(null);
const learningItems: LearningItem[] = [
  {
    title: "Preliminares Que Fazem a Diferença",
    content:
      "Aprenda a arte das preliminares e descubra técnicas eficazes para despertar a sensualidade da sua parceira. Explore beijos apaixonados, carícias sensuais, massagens relaxantes e toques que a levem ao ápice do desejo.",
  },
  {
    title: "Estimulação do Clitóris Que Leva ao Clímax",
    content:
      "Dominar a arte da estimulação do clitóris é fundamental para proporcionar orgasmos intensos e memoráveis. Descubra as melhores técnicas, os movimentos mais eficazes e os segredos para levar sua parceira ao clímax.",
  },
  {
    title: "Exploração do Ponto G",
    content:
      "Aprenda a localização precisa do Ponto G, como estimulá-lo de forma eficaz e quais são os movimentos que maximizam o prazer e a intensidade do orgasmo.",
  },
];

const togglePanel = (index: number) => {
  activeIndex.value = activeIndex.value === index ? null : index;
};

const buttonText: ButtonConfig = {
  largeScreen: "Adquira Agora e Obtenha 15% de Desconto!",
  smallScreen: "Obtenha 15% de Desconto!",
};

const buttonText2: ButtonConfig = {
  largeScreen: "Transforme sua relação: obtenha agora!",
  smallScreen: "Transforme sua relação!",
};

const buttonText3: ButtonConfig = {
  largeScreen: "Adquira Agora e Obtenha 15% de Desconto!",
  smallScreen: "Adquira Agora e Obtenha 15% OFF",
};

function updateButtonText(): void {
  const buttons = [
    { ref: buttonText, selector: ".text-change-promo, .hero-button" },
    { ref: buttonText2, selector: ".header-button, .pleasure-button-change" },
    {
      ref: buttonText3,
      selector: ".cta-button-position, .header-button, .hero-button",
    },
  ];

  buttons.forEach(({ ref, selector }) => {
    const buttonElements = document.querySelectorAll(selector);
    buttonElements.forEach((buttonElement) => {
      (buttonElement as HTMLElement).textContent = getButtonText(ref);
      (buttonElement as HTMLElement).style.visibility = "visible";
    });
  });
}

function getButtonText(buttonConfig: ButtonConfig): string {
  return window.innerWidth <= 768
    ? buttonConfig.smallScreen
    : buttonConfig.largeScreen;
}

onMounted(() => {
  setTimeout(updateButtonText, 100);
  window.addEventListener("resize", updateButtonText);
});

onUnmounted(() => {
  window.removeEventListener("resize", updateButtonText);
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _cache[25] || (_cache[25] = _createStaticVNode("<div class=\"circles circle1 circle-left\" data-v-162c4687></div><div class=\"circles circle1 circle-right\" data-v-162c4687></div><div class=\"circles circle2 circle2-left\" data-v-162c4687></div><div class=\"circles circle2 circle2-right\" data-v-162c4687></div><div class=\"circles circle3 circle3-left\" data-v-162c4687></div><div class=\"circles circle3 circle3-right\" data-v-162c4687></div>", 6)),
    _createElementVNode("header", { class: "header-container" }, [
      _createElementVNode("div", { class: "content-wrapper" }, [
        _createElementVNode("div", { class: "text-content" }, [
          _cache[0] || (_cache[0] = _createElementVNode("div", { class: "logo-desktop" }, [
            _createElementVNode("img", {
              src: _imports_0,
              class: "desktop-logo",
              alt: ""
            })
          ], -1)),
          _cache[1] || (_cache[1] = _createElementVNode("img", {
            src: _imports_0,
            class: "logo-header"
          }, null, -1)),
          _cache[2] || (_cache[2] = _createElementVNode("h1", null, "A Arte da Sedução: Desperte a Intimidade e o Desejo", -1)),
          _cache[3] || (_cache[3] = _createElementVNode("p", null, " Quer transformar a conexão com seu parceiro e reacender o desejo? Este guia oferece dicas práticas e fáceis de seguir para sair da rotina e criar momentos inesquecíveis. Com técnicas inovadoras e sugestões de posições, você aprenderá a aumentar a química e surpreender seu parceiro de maneiras simples, mas poderosas. Perfeito para qualquer tipo de relacionamento ou para quem quer aprender algo novo. ", -1)),
          _createElementVNode("a", {
            href: "https://pay.cakto.com.br/9sD9B8T",
            target: "_blank",
            class: "cta-button header-button"
          }, _toDisplayString(buttonText3))
        ]),
        _cache[4] || (_cache[4] = _createElementVNode("div", { class: "image-content" }, [
          _createElementVNode("img", {
            src: _imports_1,
            alt: "Couple enjoying intimacy"
          })
        ], -1))
      ])
    ]),
    _createElementVNode("section", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _cache[6] || (_cache[6] = _createElementVNode("h2", null, "O Segredo Para Proporcionar Prazer!", -1)),
          _createElementVNode("ul", _hoisted_4, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(steps, (step, index) => {
              return _createElementVNode("li", { key: index }, [
                _createElementVNode("div", _hoisted_5, [
                  _cache[5] || (_cache[5] = _createElementVNode("img", {
                    src: _imports_2,
                    alt: "Step Icon"
                  }, null, -1)),
                  _createElementVNode("span", _hoisted_6, _toDisplayString(index + 1), 1)
                ]),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("h3", null, _toDisplayString(step.title), 1),
                  _createElementVNode("p", null, _toDisplayString(step.description), 1)
                ])
              ])
            }), 64))
          ]),
          _cache[7] || (_cache[7] = _createElementVNode("a", {
            href: "https://pay.cakto.com.br/9sD9B8T",
            target: "_blank",
            class: "cta-button"
          }, "Clique Aqui para Garantir!", -1))
        ]),
        _cache[8] || (_cache[8] = _createElementVNode("div", { class: "container-img-section" }, [
          _createElementVNode("div", { class: "image-section" }, [
            _createElementVNode("img", {
              src: _imports_3,
              alt: "Intimate couple"
            })
          ])
        ], -1))
      ])
    ]),
    _createElementVNode("section", _hoisted_8, [
      _createElementVNode("div", _hoisted_9, [
        _createElementVNode("div", _hoisted_10, [
          _cache[9] || (_cache[9] = _createElementVNode("h2", null, "Domine a Arte da Sedução!", -1)),
          _createElementVNode("div", _hoisted_11, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(features, (feature, index) => {
              return _createElementVNode("div", {
                class: "feature",
                key: index
              }, [
                _createElementVNode("h3", null, _toDisplayString(feature.title), 1),
                _createElementVNode("p", null, _toDisplayString(feature.description), 1)
              ])
            }), 64))
          ]),
          _cache[10] || (_cache[10] = _createElementVNode("a", {
            href: "https://pay.cakto.com.br/9sD9B8T",
            target: "_blank",
            class: "cta-button"
          }, "Adquira Já e Atraia o Prazer!", -1))
        ])
      ])
    ]),
    _createElementVNode("section", _hoisted_12, [
      _createElementVNode("div", _hoisted_13, [
        _createElementVNode("div", _hoisted_14, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(countdown.value, (time, unit) => {
            return (_openBlock(), _createElementBlock("div", {
              class: "time-unit",
              key: unit
            }, [
              _createElementVNode("span", null, _toDisplayString(time), 1),
              _createElementVNode("p", null, _toDisplayString(unit.toUpperCase()), 1)
            ]))
          }), 128))
        ]),
        _cache[11] || (_cache[11] = _createElementVNode("div", { class: "promo-text-container" }, [
          _createElementVNode("p", { class: "promo-text" }, [
            _createTextVNode(" O seu acesso nesta página garante as "),
            _createElementVNode("span", { class: "highlight" }, "ofertas de promoção"),
            _createTextVNode(" deste mês durante o tempo deste contador ")
          ])
        ], -1)),
        _createElementVNode("div", null, [
          _createElementVNode("a", {
            href: "https://pay.cakto.com.br/9sD9B8T",
            target: "_blank",
            class: "text-change-promo cta-button"
          }, _toDisplayString(buttonText))
        ])
      ]),
      _createElementVNode("div", _hoisted_15, [
        _createElementVNode("div", {
          class: "scrolling-text",
          ref_key: "scrollingText",
          ref: scrollingText
        }, [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(20, (n) => {
            return _createElementVNode("span", { key: n }, "15% OFF")
          }), 64))
        ], 512)
      ])
    ]),
    _cache[26] || (_cache[26] = _createStaticVNode("<section class=\"mockup-section\" data-v-162c4687><div class=\"mockup-container\" data-v-162c4687><img src=\"" + _imports_4 + "\" alt=\"Mockup Celular Esquerdo\" data-v-162c4687><img src=\"" + _imports_5 + "\" alt=\"Mockup Celular Direito\" data-v-162c4687></div><div class=\"text-container\" data-v-162c4687><h2 data-v-162c4687> A Fórmula Perfeita Para Recuperar o <span data-v-162c4687>Prazer</span> e <span data-v-162c4687>Intimidade</span>. Ideal para: </h2><div class=\"text-row\" data-v-162c4687><div class=\"text-item\" data-v-162c4687><img src=\"" + _imports_6 + "\" alt=\"Gota\" data-v-162c4687><p data-v-162c4687> Para as casadas que desejam reavivar a paixão e desfrutar de momentos íntimos mais prazerosos. </p></div><div class=\"text-item\" data-v-162c4687><img src=\"" + _imports_6 + "\" alt=\"Gota\" data-v-162c4687><p data-v-162c4687> Para as jovens e adultas que buscam uma vida sexual vibrante e cheia de fogo. </p></div><div class=\"text-item\" data-v-162c4687><img src=\"" + _imports_6 + "\" alt=\"Gota\" data-v-162c4687><p data-v-162c4687> Para mulheres solteiras em busca de mais energia e realização na vida amorosa. </p></div></div><div class=\"text-row\" data-v-162c4687><div class=\"text-item\" data-v-162c4687><img src=\"" + _imports_6 + "\" alt=\"Gota\" data-v-162c4687><p data-v-162c4687> Para as mulheres cujos hormônios precisam de equilíbrio e que anseiam recuperar sua libido. </p></div><div class=\"text-item\" data-v-162c4687><img src=\"" + _imports_6 + "\" alt=\"Gota\" data-v-162c4687><p data-v-162c4687> Para as amantes do prazer genuíno, buscando a elevação das sensações e experiências. </p></div><div class=\"text-item\" data-v-162c4687><img src=\"" + _imports_6 + "\" alt=\"Gota\" data-v-162c4687><p data-v-162c4687> Para as mães que passaram pela gestação e agora buscam reconectar-se com a intimidade. </p></div></div></div></section>", 1)),
    _createElementVNode("section", { class: "pleasure-section" }, [
      _cache[13] || (_cache[13] = _createElementVNode("div", { class: "overlay" }, null, -1)),
      _createElementVNode("div", { class: "content-container" }, [
        _cache[12] || (_cache[12] = _createStaticVNode("<h2 data-v-162c4687>Seja o Protagonista do Prazer!</h2><div class=\"pleasure-cards\" data-v-162c4687><div class=\"card\" data-v-162c4687><div class=\"card-number\" data-v-162c4687>1</div><div class=\"card-content\" data-v-162c4687><h3 data-v-162c4687>Intimidade e Confiança</h3><p data-v-162c4687> Construa uma base sólida de intimidade e confiança com sua parceira. A comunicação aberta, a empatia e a compreensão mútua são essenciais para uma relação sexual plena e satisfatória. </p></div></div><div class=\"card\" data-v-162c4687><div class=\"card-number\" data-v-162c4687>2</div><div class=\"card-content\" data-v-162c4687><h3 data-v-162c4687>Exploração e Descoberta</h3><p data-v-162c4687> Desvendem juntos os desejos e preferências um do outro, abram-se para novas experiências e aprendam a se comunicar de forma clara e honesta sobre seus desejos e necessidades. </p></div></div><div class=\"card\" data-v-162c4687><div class=\"card-number\" data-v-162c4687>3</div><div class=\"card-content\" data-v-162c4687><h3 data-v-162c4687>Compartilhar Emoções</h3><p data-v-162c4687> A intimidade sexual é uma oportunidade de compartilhar emoções, fortalecer os laços e aprofundar a conexão. Expresse seus sentimentos, deixe-se levar pela paixão e desfrute da cumplicidade. </p></div></div><div class=\"card\" data-v-162c4687><div class=\"card-number\" data-v-162c4687>4</div><div class=\"card-content\" data-v-162c4687><h3 data-v-162c4687>Prazer Mútuo</h3><p data-v-162c4687> O foco deve estar no prazer mútuo, na busca pelo bem-estar e na satisfação de ambos. Aprenda a dar e receber prazer, reconhecendo as necessidades e desejos do outro. </p></div></div></div>", 2)),
        _createElementVNode("a", {
          href: "https://pay.cakto.com.br/9sD9B8T",
          target: "_blank",
          class: "cta-button-pleasure pleasure-button-change"
        }, _toDisplayString(buttonText2))
      ])
    ]),
    _createElementVNode("section", _hoisted_16, [
      _createElementVNode("div", _hoisted_17, [
        _cache[15] || (_cache[15] = _createElementVNode("h2", null, "As Melhores Posições Para Explorar", -1)),
        _createElementVNode("div", _hoisted_18, [
          _createElementVNode("table", null, [
            _cache[14] || (_cache[14] = _createElementVNode("thead", null, [
              _createElementVNode("tr", null, [
                _createElementVNode("th", null, "Posição"),
                _createElementVNode("th", null, "Benefícios")
              ])
            ], -1)),
            _createElementVNode("tbody", null, [
              (_openBlock(), _createElementBlock(_Fragment, null, _renderList(positions, (position, index) => {
                return _createElementVNode("tr", { key: index }, [
                  _createElementVNode("td", null, _toDisplayString(position.name), 1),
                  _createElementVNode("td", null, _toDisplayString(position.benefits), 1)
                ])
              }), 64))
            ])
          ])
        ]),
        _cache[16] || (_cache[16] = _createElementVNode("a", {
          href: "https://pay.cakto.com.br/9sD9B8T",
          target: "_blank",
          class: "cta-button-position"
        }, " Garanta e Seja o Protagonista do Prazer! ", -1))
      ])
    ]),
    _createElementVNode("section", null, [
      _createElementVNode("div", _hoisted_19, [
        _createElementVNode("div", _hoisted_20, [
          _cache[17] || (_cache[17] = _createElementVNode("h2", null, "O Que Você Vai Aprender:", -1)),
          _createElementVNode("ul", _hoisted_21, [
            (_openBlock(), _createElementBlock(_Fragment, null, _renderList(learningItems, (item, index) => {
              return _createElementVNode("li", { key: index }, [
                _createElementVNode("button", {
                  class: _normalizeClass(["accordion", { active: activeIndex.value === index }]),
                  onClick: ($event: any) => (togglePanel(index))
                }, _toDisplayString(item.title), 11, _hoisted_22),
                _withDirectives(_createElementVNode("div", _hoisted_23, [
                  _createElementVNode("p", null, _toDisplayString(item.content), 1)
                ], 512), [
                  [_vShow, activeIndex.value === index]
                ])
              ])
            }), 64))
          ])
        ])
      ])
    ]),
    _createElementVNode("section", { class: "hero-section" }, [
      _createElementVNode("div", { class: "container" }, [
        _cache[21] || (_cache[21] = _createElementVNode("div", { class: "hero-overlay" }, null, -1)),
        _createElementVNode("div", { class: "hero-content" }, [
          _createElementVNode("div", { class: "hero-text" }, [
            _cache[18] || (_cache[18] = _createElementVNode("h1", null, "Desperte o Potencial de Prazer e Intimidade!", -1)),
            _cache[19] || (_cache[19] = _createElementVNode("p", null, " Não perca tempo! Adquira seu exemplar do \"Guia para o Orgasmo Feminino\" agora mesmo e comece a transformar sua vida sexual e sua relação. Descubra os segredos para proporcionar à sua parceira orgasmos intensos e memoráveis, intensificando a intimidade e o prazer. ", -1)),
            _createElementVNode("a", {
              href: "https://pay.cakto.com.br/9sD9B8T",
              target: "_blank",
              class: "cta-button hero-button"
            }, _toDisplayString(buttonText3))
          ]),
          _cache[20] || (_cache[20] = _createElementVNode("img", {
            src: _imports_7,
            alt: "Garantia de 7 Dias",
            class: "guarantee-badge"
          }, null, -1))
        ])
      ])
    ]),
    _createElementVNode("footer", _hoisted_24, [
      _createElementVNode("div", _hoisted_25, [
        _createElementVNode("div", _hoisted_26, [
          _cache[22] || (_cache[22] = _createElementVNode("img", {
            src: _imports_0,
            alt: "Secret Sedução Logo",
            class: "footer-logo"
          }, null, -1)),
          _createElementVNode("ul", _hoisted_27, [
            _createElementVNode("li", null, [
              _createElementVNode("button", {
                class: "footer-links-a",
                href: "#",
                onClick: _withModifiers(openModal, ["prevent"])
              }, " Termos de Uso ")
            ]),
            _createElementVNode("li", null, [
              _createElementVNode("button", {
                class: "footer-links-a",
                href: "#",
                onClick: _withModifiers(openModalPrivacidade, ["prevent"])
              }, " Política de Privacidade ")
            ])
          ])
        ]),
        _cache[23] || (_cache[23] = _createElementVNode("div", { class: "footer-center" }, [
          _createElementVNode("h4", null, "AVISO"),
          _createElementVNode("p", null, " Este site não faz parte do site do Facebook ou do Facebook Inc. Além disso, este site NÃO é endossado pelo Facebook de forma alguma. FACEBOOK é uma marca comercial da FACEBOOK, Inc. Para acessar clique no botão abaixo. ")
        ], -1)),
        _cache[24] || (_cache[24] = _createElementVNode("div", { class: "footer-right" }, [
          _createElementVNode("h4", null, "FORMAS DE PAGAMENTO"),
          _createElementVNode("img", {
            src: _imports_8,
            alt: "Formas de Pagamento",
            class: "payment-icons"
          }),
          _createElementVNode("img", {
            src: _imports_9,
            alt: "Selos de Segurança",
            class: "security-icons"
          })
        ], -1))
      ])
    ]),
    _createVNode(TermosDeUsoModal, {
      isOpen: isModalOpen.value,
      onClose: closeModal
    }, null, 8, ["isOpen"]),
    _createVNode(PoliticasDePrivacidade, {
      isOpen: isModalPrivOpen.value,
      onClose: closePrivModal
    }, null, 8, ["isOpen"])
  ], 64))
}
}

})