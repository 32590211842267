<template>
  <div class="modal-overlay" v-if="isOpen" @click="closeModal">
    <div class="modal-content" @click.stop>
      <h2>TERMOS DE USO</h2>
      <div class="modal-body" v-html="renderedMarkdown"></div>
      <button @click="closeModal">Fechar</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, computed } from "vue";
import { marked } from "marked";

// Define as props e emite eventos
defineProps<{
  isOpen: boolean;
}>();

const emit = defineEmits<{
  (event: "close"): void;
}>();

// Função para fechar o modal
const closeModal = () => {
  emit("close");
};

// Conteúdo Markdown dos Termos de Uso
const markdownContent = `

Estes Termos de Uso estabelecem as condições para o uso de nossos serviços e produtos, assegurando uma experiência clara e segura.

<br>

**Aceitação dos Termos**

Ao acessar nossos serviços, você concorda com todos os termos e condições aqui descritos. Se discordar de qualquer parte, deve interromper o uso da plataforma.

<br>

**Requisitos de Idade**

Nossos produtos são destinados exclusivamente a maiores de 18 anos. Usuários menores devem ter consentimento dos pais ou responsáveis legais.

<br>

**Cadastro e Segurança da Conta**

Você deve fornecer informações verdadeiras ao cadastrar-se. É responsável por proteger suas credenciais de acesso e notificar sobre qualquer uso não autorizado.

<br>

**Uso Adequado dos Serviços**

Ao utilizar nossos serviços, você se compromete a seguir todas as instruções e diretrizes fornecidas, garantindo que o uso do produto seja pessoal e conforme os propósitos para os quais foi adquirido. Não deve utilizar o produto de forma que possa prejudicar a integridade da marca Secret Sedução.

<br>

**Propriedade Intelectual**

Todo o conteúdo, incluindo textos, imagens e marcas, pertence ao Secret Sedução ou a terceiros licenciados, e não pode ser reproduzido sem autorização.

<br>

**Pagamentos e Assinaturas**

Detalhes sobre preços e formas de pagamento estão disponíveis na página de checkout. Você concorda em pagar todos os encargos associados à sua compra.

<br>

**Política de Reembolso**

Reembolsos são concedidos de acordo com a política específica de cada produto. Consulte nossas diretrizes de reembolso para mais informações.

<br>

**Modificações nos Termos**

Reservamo-nos o direito de alterar estes termos a qualquer momento. Notificaremos mudanças significativas e recomendamos revisar os termos regularmente.

<br>

**Limitação de Responsabilidade**

Não nos responsabilizamos por perdas ou danos relacionados ao uso da plataforma, incluindo interrupções temporárias do serviço.

<br>

---
<br>

**Contato para Suporte**

Para dúvidas ou suporte, entre em contato pelo e-mail [suporte@secretseducacao.com.br](mailto:suporte@secretseducacao.com.br "‌"). Estamos disponíveis para esclarecer quaisquer questões relacionadas ao uso de nossos serviços.
`;

// Renderiza o Markdown como HTML
const renderedMarkdown = computed(() => marked(markdownContent));
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: rgb(0, 0, 0);
  padding: 30px;
  border-radius: 12px;
  max-width: 800px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.modal-content h2 {
  font-size: 24px;
  font-weight: bold;
  color: #ff0000;
  margin-bottom: 20px;
  text-align: center;
}

.modal-body {
  margin: 20px 0;
  color: #d1d1d1;
}

button {
  padding: 12px 24px;
  background-color: #ff0000;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  display: block;
  margin: 20px auto 0;
}

button:hover {
  background-color: #740000;
}
</style>
