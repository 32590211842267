<template>
  <div class="circles circle1 circle-left"></div>
  <div class="circles circle1 circle-right"></div>

  <div class="circles circle2 circle2-left"></div>
  <div class="circles circle2 circle2-right"></div>

  <div class="circles circle3 circle3-left"></div>
  <div class="circles circle3 circle3-right"></div>

  <header class="header-container">
    <div class="content-wrapper">
      <div class="text-content">
        <div class="logo-desktop">
          <img
            src="../../public/Images/secret_logo.png"
            class="desktop-logo"
            alt=""
          />
        </div>
        <img src="../../public/Images/secret_logo.png" class="logo-header" />
        <h1>A Arte da Sedução: Desperte a Intimidade e o Desejo</h1>
        <p>
          Quer transformar a conexão com seu parceiro e reacender o desejo? Este
          guia oferece dicas práticas e fáceis de seguir para sair da rotina e
          criar momentos inesquecíveis. Com técnicas inovadoras e sugestões de
          posições, você aprenderá a aumentar a química e surpreender seu
          parceiro de maneiras simples, mas poderosas. Perfeito para qualquer
          tipo de relacionamento ou para quem quer aprender algo novo.
        </p>
        <a
          href="https://pay.cakto.com.br/9sD9B8T"
          target="_blank"
          class="cta-button header-button"
          >{{ buttonText3 }}</a
        >
      </div>
      <div class="image-content">
        <img
          src="../../public/Images/header.png"
          alt="Couple enjoying intimacy"
        />
      </div>
    </div>
  </header>

  <section class="section-container">
    <div class="section-wrapper">
      <div class="text-section">
        <h2>O Segredo Para Proporcionar Prazer!</h2>
        <ul class="steps-list">
          <li v-for="(step, index) in steps" :key="index">
            <div class="step-icon">
              <img src="../../public/Images/Vector.png" alt="Step Icon" />
              <span class="step-number">{{ index + 1 }}</span>
            </div>
            <div class="step-text">
              <h3>{{ step.title }}</h3>
              <p>{{ step.description }}</p>
            </div>
          </li>
        </ul>
        <a
          href="https://pay.cakto.com.br/9sD9B8T"
          target="_blank"
          class="cta-button"
          >Clique Aqui para Garantir!</a
        >
      </div>
      <div class="container-img-section">
        <div class="image-section">
          <img src="../../public/Images/section1.jpg" alt="Intimate couple" />
        </div>
      </div>
    </div>
  </section>

  <section class="seduction-section">
    <div class="container-seduction">
      <div class="seduction">
        <h2>Domine a Arte da Sedução!</h2>
        <div class="features">
          <div
            class="feature"
            v-for="(feature, index) in features"
            :key="index"
          >
            <h3>{{ feature.title }}</h3>
            <p>{{ feature.description }}</p>
          </div>
        </div>
        <a
          href="https://pay.cakto.com.br/9sD9B8T"
          target="_blank"
          class="cta-button"
          >Adquira Já e Atraia o Prazer!</a
        >
      </div>
    </div>
  </section>

  <section class="promo-section">
    <div class="container-promo">
      <div class="timer">
        <div class="time-unit" v-for="(time, unit) in countdown" :key="unit">
          <span>{{ time }}</span>
          <p>{{ unit.toUpperCase() }}</p>
        </div>
      </div>
      <div class="promo-text-container">
        <p class="promo-text">
          O seu acesso nesta página garante as
          <span class="highlight">ofertas de promoção</span> deste mês durante o
          tempo deste contador
        </p>
      </div>
      <div>
        <a
          href="https://pay.cakto.com.br/9sD9B8T"
          target="_blank"
          class="text-change-promo cta-button"
          >{{ buttonText }}</a
        >
      </div>
    </div>
    <div class="footer-banner">
      <div class="scrolling-text" ref="scrollingText">
        <span v-for="n in 20" :key="n">15% OFF</span>
      </div>
    </div>
  </section>

  <section class="mockup-section">
    <div class="mockup-container">
      <img src="../../public/Images/cel1.png" alt="Mockup Celular Esquerdo" />
      <img src="../../public/Images/cel2.png" alt="Mockup Celular Direito" />
    </div>
    <div class="text-container">
      <h2>
        A Fórmula Perfeita Para Recuperar o <span>Prazer</span> e
        <span>Intimidade</span>. Ideal para:
      </h2>
      <div class="text-row">
        <div class="text-item">
          <img src="../../public/Images/gota.png" alt="Gota" />
          <p>
            Para as casadas que desejam reavivar a paixão e desfrutar de
            momentos íntimos mais prazerosos.
          </p>
        </div>
        <div class="text-item">
          <img src="../../public/Images/gota.png" alt="Gota" />
          <p>
            Para as jovens e adultas que buscam uma vida sexual vibrante e cheia
            de fogo.
          </p>
        </div>
        <div class="text-item">
          <img src="../../public/Images/gota.png" alt="Gota" />
          <p>
            Para mulheres solteiras em busca de mais energia e realização na
            vida amorosa.
          </p>
        </div>
      </div>
      <div class="text-row">
        <div class="text-item">
          <img src="../../public/Images/gota.png" alt="Gota" />
          <p>
            Para as mulheres cujos hormônios precisam de equilíbrio e que
            anseiam recuperar sua libido.
          </p>
        </div>
        <div class="text-item">
          <img src="../../public/Images/gota.png" alt="Gota" />
          <p>
            Para as amantes do prazer genuíno, buscando a elevação das sensações
            e experiências.
          </p>
        </div>
        <div class="text-item">
          <img src="../../public/Images/gota.png" alt="Gota" />
          <p>
            Para as mães que passaram pela gestação e agora buscam reconectar-se
            com a intimidade.
          </p>
        </div>
      </div>
    </div>
  </section>

  <section class="pleasure-section">
    <div class="overlay"></div>
    <div class="content-container">
      <h2>Seja o Protagonista do Prazer!</h2>
      <div class="pleasure-cards">
        <div class="card">
          <div class="card-number">1</div>
          <div class="card-content">
            <h3>Intimidade e Confiança</h3>
            <p>
              Construa uma base sólida de intimidade e confiança com sua
              parceira. A comunicação aberta, a empatia e a compreensão mútua
              são essenciais para uma relação sexual plena e satisfatória.
            </p>
          </div>
        </div>
        <div class="card">
          <div class="card-number">2</div>
          <div class="card-content">
            <h3>Exploração e Descoberta</h3>
            <p>
              Desvendem juntos os desejos e preferências um do outro, abram-se
              para novas experiências e aprendam a se comunicar de forma clara e
              honesta sobre seus desejos e necessidades.
            </p>
          </div>
        </div>
        <div class="card">
          <div class="card-number">3</div>
          <div class="card-content">
            <h3>Compartilhar Emoções</h3>
            <p>
              A intimidade sexual é uma oportunidade de compartilhar emoções,
              fortalecer os laços e aprofundar a conexão. Expresse seus
              sentimentos, deixe-se levar pela paixão e desfrute da
              cumplicidade.
            </p>
          </div>
        </div>
        <div class="card">
          <div class="card-number">4</div>
          <div class="card-content">
            <h3>Prazer Mútuo</h3>
            <p>
              O foco deve estar no prazer mútuo, na busca pelo bem-estar e na
              satisfação de ambos. Aprenda a dar e receber prazer, reconhecendo
              as necessidades e desejos do outro.
            </p>
          </div>
        </div>
      </div>
      <a
        href="https://pay.cakto.com.br/9sD9B8T"
        target="_blank"
        class="cta-button-pleasure pleasure-button-change"
      >
        {{ buttonText2 }}
      </a>
    </div>
  </section>

  <section class="positions-section">
    <div class="content-container">
      <h2>As Melhores Posições Para Explorar</h2>
      <div class="table-container">
        <table>
          <thead>
            <tr>
              <th>Posição</th>
              <th>Benefícios</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(position, index) in positions" :key="index">
              <td>{{ position.name }}</td>
              <td>{{ position.benefits }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <a
        href="https://pay.cakto.com.br/9sD9B8T"
        target="_blank"
        class="cta-button-position"
      >
        Garanta e Seja o Protagonista do Prazer!
      </a>
    </div>
  </section>

  <section>
    <div class="container-learning">
      <div class="learning-section">
        <h2>O Que Você Vai Aprender:</h2>
        <ul class="learning-list">
          <li v-for="(item, index) in learningItems" :key="index">
            <button
              class="accordion"
              :class="{ active: activeIndex === index }"
              @click="togglePanel(index)"
            >
              {{ item.title }}
            </button>
            <div class="panel" v-show="activeIndex === index">
              <p>{{ item.content }}</p>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </section>

  <section class="hero-section">
    <div class="container">
      <div class="hero-overlay"></div>
      <div class="hero-content">
        <div class="hero-text">
          <h1>Desperte o Potencial de Prazer e Intimidade!</h1>
          <p>
            Não perca tempo! Adquira seu exemplar do "Guia para o Orgasmo
            Feminino" agora mesmo e comece a transformar sua vida sexual e sua
            relação. Descubra os segredos para proporcionar à sua parceira
            orgasmos intensos e memoráveis, intensificando a intimidade e o
            prazer.
          </p>
          <a
            href="https://pay.cakto.com.br/9sD9B8T"
            target="_blank"
            class="cta-button hero-button"
            >{{ buttonText3 }}</a
          >
        </div>
        <img
          src="../../public/Images/selo.png"
          alt="Garantia de 7 Dias"
          class="guarantee-badge"
        />
      </div>
    </div>
  </section>

  <footer class="footer">
    <div class="footer-container">
      <div class="footer-left">
        <img
          src="../../public/Images/secret_logo.png"
          alt="Secret Sedução Logo"
          class="footer-logo"
        />
        <ul class="footer-links">
          <li>
            <button class="footer-links-a" href="#" @click.prevent="openModal">
              Termos de Uso
            </button>
          </li>
          <li>
            <button
              class="footer-links-a"
              href="#"
              @click.prevent="openModalPrivacidade"
            >
              Política de Privacidade
            </button>
          </li>
        </ul>
      </div>

      <div class="footer-center">
        <h4>AVISO</h4>
        <p>
          Este site não faz parte do site do Facebook ou do Facebook Inc. Além
          disso, este site NÃO é endossado pelo Facebook de forma alguma.
          FACEBOOK é uma marca comercial da FACEBOOK, Inc. Para acessar clique
          no botão abaixo.
        </p>
      </div>

      <div class="footer-right">
        <h4>FORMAS DE PAGAMENTO</h4>
        <img
          src="../../public/Images/cartoes.png"
          alt="Formas de Pagamento"
          class="payment-icons"
        />
        <img
          src="../../public/Images/selos2.png"
          alt="Selos de Segurança"
          class="security-icons"
        />
      </div>
    </div>
  </footer>

  <TermosDeUsoModal :isOpen="isModalOpen" @close="closeModal" />
  <PoliticasDePrivacidade :isOpen="isModalPrivOpen" @close="closePrivModal" />
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from "vue";
import TermosDeUsoModal from "@/components/TermosDeUsoModal.vue";
import PoliticasDePrivacidade from "@/components/PoliticasDePrivacidade.vue";

const isModalOpen = ref(false);
const isModalPrivOpen = ref(false);

const openModal = () => {
  isModalOpen.value = true;
};

const openModalPrivacidade = () => {
  isModalPrivOpen.value = true;
};

const closePrivModal = () => {
  isModalPrivOpen.value = false;
};

const closeModal = () => {
  isModalOpen.value = false;
};

const scrollingText = ref<HTMLDivElement | null>(null);

onMounted(() => {
  const textContainer = scrollingText.value;

  if (textContainer) {
    const containerWidth = textContainer.offsetWidth;
    const contentWidth = textContainer.scrollWidth;

    if (contentWidth < containerWidth * 2) {
      const originalContent = textContainer.innerHTML;
      textContainer.innerHTML += originalContent;
    }

    const totalWidth = textContainer.scrollWidth;
    textContainer.style.animationDuration = `${totalWidth / 100}s`;
  }
});

const steps = [
  {
    title: "Entenda a Anatomia Feminina",
    description:
      "Comece por compreender as zonas erógenas femininas e como a estimulação de cada área contribui para o prazer.",
  },
  {
    title: "Comunicação Aberta e Sincera",
    description:
      "Construa uma atmosfera de confiança e comunicação aberta com sua parceira. Pergunte o que ela gosta e quais são seus desejos, para que possa oferecer o prazer que ela realmente busca.",
  },
  {
    title: "Domine a Arte das Preliminares",
    description:
      "Dedique tempo às preliminares e explore diferentes toques, beijos e carícias que despertem a sensualidade da sua parceira.",
  },
  {
    title: "Explore Posições Inovadoras",
    description:
      "Varie as posições sexuais e descubra quais delas despertam o maior prazer em sua parceira.",
  },
  {
    title: "Aprenda Técnicas de Estimulação",
    description:
      "Desvende as melhores técnicas para estimular o clitóris, o Ponto G e outras áreas sensíveis proporcionando orgasmos intensos e satisfatórios para sua parceira.",
  },
  {
    title: "Conecte-se Emocionalmente",
    description:
      "O prazer sexual vai além do físico. Crie uma conexão emocional forte com sua parceira demonstre afeto, carinho e atenção, intensificando a experiência íntima.",
  },
];

const features = [
  {
    title: "Linguagem Corporal e Sedução",
    description:
      "O olhar, o toque, a postura e a maneira como você se move podem comunicar muito sobre seus desejos e intensificar o clima de sedução. Aprenda a usar a linguagem corporal a seu favor.",
  },
  {
    title: "Crie um Ambiente Romântico",
    description:
      "Um ambiente relaxante, com música suave, iluminação suave, aromas agradáveis e um toque de sensualidade contribui para a atmosfera ideal para a sedução e o prazer.",
  },
  {
    title: "Desperte os Sentidos",
    description:
      "Explore diferentes texturas, aromas, sabores e sons para estimular os sentidos da sua parceira. Use a criatividade para criar uma experiência multissensorial que intensifique o prazer.",
  },
];

const positions = [
  {
    name: "Clássica e versátil",
    benefits: "Permite fácil acesso ao clitóris e ao ponto G.",
  },
  {
    name: "O Controle nas Mãos",
    benefits:
      "Quando assumir o comando transforma a intensidade em algo memorável.",
  },
  {
    name: "Movimentos Livres e Ousados",
    benefits:
      "Oferece maior liberdade de movimento para a mulher, intensificando o prazer e o controle.",
  },
  {
    name: "Intimidade Redefinida",
    benefits:
      "Uma posição que aproxima, intensifica os toques e cria momentos inesquecíveis.",
  },
  {
    name: "Acesso às Sensações Mais Intensas",
    benefits:
      "Proporciona experiências profundas que deixam marcas na memória.",
  },
];

const countdown = ref({
  dias: "00",
  horas: "00",
  minutos: "00",
  segundos: "00",
});
const countdownTarget = new Date().getTime() + 2 * 60 * 60 * 1000;

const startCountdown = () => {
  setInterval(() => {
    const now = new Date().getTime();
    const distance = countdownTarget - now;

    if (distance <= 0) return;
    countdown.value = {
      dias: String(Math.floor(distance / (1000 * 60 * 60 * 24))).padStart(
        2,
        "0"
      ),
      horas: String(
        Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60))
      ).padStart(2, "0"),
      minutos: String(
        Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      ).padStart(2, "0"),
      segundos: String(Math.floor((distance % (1000 * 60)) / 1000)).padStart(
        2,
        "0"
      ),
    };
  }, 1000);
};

onMounted(() => {
  startCountdown();
});

type LearningItem = {
  title: string;
  content: string;
};

const activeIndex = ref<number | null>(null);
const learningItems: LearningItem[] = [
  {
    title: "Preliminares Que Fazem a Diferença",
    content:
      "Aprenda a arte das preliminares e descubra técnicas eficazes para despertar a sensualidade da sua parceira. Explore beijos apaixonados, carícias sensuais, massagens relaxantes e toques que a levem ao ápice do desejo.",
  },
  {
    title: "Estimulação do Clitóris Que Leva ao Clímax",
    content:
      "Dominar a arte da estimulação do clitóris é fundamental para proporcionar orgasmos intensos e memoráveis. Descubra as melhores técnicas, os movimentos mais eficazes e os segredos para levar sua parceira ao clímax.",
  },
  {
    title: "Exploração do Ponto G",
    content:
      "Aprenda a localização precisa do Ponto G, como estimulá-lo de forma eficaz e quais são os movimentos que maximizam o prazer e a intensidade do orgasmo.",
  },
];

const togglePanel = (index: number) => {
  activeIndex.value = activeIndex.value === index ? null : index;
};

interface ButtonConfig {
  largeScreen: string;
  smallScreen: string;
}

const buttonText: ButtonConfig = {
  largeScreen: "Adquira Agora e Obtenha 15% de Desconto!",
  smallScreen: "Obtenha 15% de Desconto!",
};

const buttonText2: ButtonConfig = {
  largeScreen: "Transforme sua relação: obtenha agora!",
  smallScreen: "Transforme sua relação!",
};

const buttonText3: ButtonConfig = {
  largeScreen: "Adquira Agora e Obtenha 15% de Desconto!",
  smallScreen: "Adquira Agora e Obtenha 15% OFF",
};

function updateButtonText(): void {
  const buttons = [
    { ref: buttonText, selector: ".text-change-promo, .hero-button" },
    { ref: buttonText2, selector: ".header-button, .pleasure-button-change" },
    {
      ref: buttonText3,
      selector: ".cta-button-position, .header-button, .hero-button",
    },
  ];

  buttons.forEach(({ ref, selector }) => {
    const buttonElements = document.querySelectorAll(selector);
    buttonElements.forEach((buttonElement) => {
      (buttonElement as HTMLElement).textContent = getButtonText(ref);
      (buttonElement as HTMLElement).style.visibility = "visible";
    });
  });
}

function getButtonText(buttonConfig: ButtonConfig): string {
  return window.innerWidth <= 768
    ? buttonConfig.smallScreen
    : buttonConfig.largeScreen;
}

onMounted(() => {
  setTimeout(updateButtonText, 100);
  window.addEventListener("resize", updateButtonText);
});

onUnmounted(() => {
  window.removeEventListener("resize", updateButtonText);
});
</script>

<style scoped>
a {
  visibility: hidden;
}

@import url(../css/main.css);
</style>
