import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["innerHTML"]

import { computed } from "vue";
import { marked } from "marked";

// Define as props e emite eventos
const markdownContent = `

Estes Termos de Uso estabelecem as condições para o uso de nossos serviços e produtos, assegurando uma experiência clara e segura.

<br>

**Aceitação dos Termos**

Ao acessar nossos serviços, você concorda com todos os termos e condições aqui descritos. Se discordar de qualquer parte, deve interromper o uso da plataforma.

<br>

**Requisitos de Idade**

Nossos produtos são destinados exclusivamente a maiores de 18 anos. Usuários menores devem ter consentimento dos pais ou responsáveis legais.

<br>

**Cadastro e Segurança da Conta**

Você deve fornecer informações verdadeiras ao cadastrar-se. É responsável por proteger suas credenciais de acesso e notificar sobre qualquer uso não autorizado.

<br>

**Uso Adequado dos Serviços**

Ao utilizar nossos serviços, você se compromete a seguir todas as instruções e diretrizes fornecidas, garantindo que o uso do produto seja pessoal e conforme os propósitos para os quais foi adquirido. Não deve utilizar o produto de forma que possa prejudicar a integridade da marca Secret Sedução.

<br>

**Propriedade Intelectual**

Todo o conteúdo, incluindo textos, imagens e marcas, pertence ao Secret Sedução ou a terceiros licenciados, e não pode ser reproduzido sem autorização.

<br>

**Pagamentos e Assinaturas**

Detalhes sobre preços e formas de pagamento estão disponíveis na página de checkout. Você concorda em pagar todos os encargos associados à sua compra.

<br>

**Política de Reembolso**

Reembolsos são concedidos de acordo com a política específica de cada produto. Consulte nossas diretrizes de reembolso para mais informações.

<br>

**Modificações nos Termos**

Reservamo-nos o direito de alterar estes termos a qualquer momento. Notificaremos mudanças significativas e recomendamos revisar os termos regularmente.

<br>

**Limitação de Responsabilidade**

Não nos responsabilizamos por perdas ou danos relacionados ao uso da plataforma, incluindo interrupções temporárias do serviço.

<br>

---
<br>

**Contato para Suporte**

Para dúvidas ou suporte, entre em contato pelo e-mail [suporte@secretseducacao.com.br](mailto:suporte@secretseducacao.com.br "‌"). Estamos disponíveis para esclarecer quaisquer questões relacionadas ao uso de nossos serviços.
`;

// Renderiza o Markdown como HTML

export default /*@__PURE__*/_defineComponent({
  __name: 'TermosDeUsoModal',
  props: {
    isOpen: { type: Boolean }
  },
  emits: ["close"],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

// Função para fechar o modal
const closeModal = () => {
  emit("close");
};

// Conteúdo Markdown dos Termos de Uso
const renderedMarkdown = computed(() => marked(markdownContent));

return (_ctx: any,_cache: any) => {
  return (_ctx.isOpen)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "modal-overlay",
        onClick: closeModal
      }, [
        _createElementVNode("div", {
          class: "modal-content",
          onClick: _cache[0] || (_cache[0] = _withModifiers(() => {}, ["stop"]))
        }, [
          _cache[1] || (_cache[1] = _createElementVNode("h2", null, "TERMOS DE USO", -1)),
          _createElementVNode("div", {
            class: "modal-body",
            innerHTML: renderedMarkdown.value
          }, null, 8, _hoisted_1),
          _createElementVNode("button", { onClick: closeModal }, "Fechar")
        ])
      ]))
    : _createCommentVNode("", true)
}
}

})